<template>
  <el-dialog
    :title="`${dataForm.id ? '修改' : '新增'}公司员工订单记录`"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item label="公司" prop="companyId">
        <el-select v-model="dataForm.companyId" filterable :disabled="true">
          <el-option
            v-for="(item, index) in companyList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="员工名字" prop="name">
        <el-input
          v-model="dataForm.name"
          :disabled="disabled"
          placeholder="员工名字"
        ></el-input>
      </el-form-item>
      <el-form-item label="取餐人" prop="consignee">
        <el-input
          v-model="dataForm.consignee"
          :disabled="disabled"
          placeholder="取餐人"
        ></el-input>
      </el-form-item>

      <el-form-item label="取餐时间" prop="pickupDate">
        <el-input
          v-model="dataForm.pickupDate"
          :disabled="disabled"
          placeholder="取餐时间"
        ></el-input>
      </el-form-item>

      <el-form-item label="手机号码" prop="mobile">
        <el-input
          v-model="dataForm.mobile"
          :disabled="disabled"
          placeholder="手机号码"
        ></el-input>
      </el-form-item>
      <el-form-item label="订单总价" prop="totalPrice">
        <el-input
          v-model="dataForm.totalPrice"
          :disabled="disabled"
          placeholder="订单总价"
        ></el-input>
      </el-form-item>
      <el-form-item label="实付金额" prop="actualPrice">
        <el-input
          v-model="dataForm.actualPrice"
          :disabled="disabled"
          placeholder="实付金额"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        consignee: '',
        companyId: '',
        pickupDate: '',
        mobile: '',
        name: '',
        totalPrice: '',
        actualPrice: '',
      },
      companyList: [],
      dataRule: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        subsidyId: [
          { required: true, message: '补助形式不能为空', trigger: 'blur' },
        ],
      },
    };
  },

  created() {},

  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.getParentCompanyId();
      this.getCompany();
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/rc/company/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm.consignee = data.employees.consignee;
              this.dataForm.pickupDate = data.employees.pickupDate;
              this.dataForm.mobile = data.employees.mobile;
              this.dataForm.name = data.employees.name;
              this.dataForm.totalPrice = data.employees.totalPrice;
              this.dataForm.actualPrice = data.employees.actualPrice;
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/rc/company/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: {
              id: this.dataForm.id || undefined,
              consignee: this.dataForm.consignee,
              pickupDate: this.dataForm.pickupDate,
              mobile: this.dataForm.mobile,
              totalPrice: this.dataForm.totalPrice,
              actualPrice: this.dataForm.actualPrice,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },

    getCompany() {
      this.$http({
        url: '/tc/company/combo',
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.getParentCompanyId();
          this.companyList = data.list;
        } else {
          this.companyList = [];
        }
      });
    },

    getParentCompanyId() {
      this.dataForm.companyId = this.$parent.companyId;
    },
  },
};
</script>
