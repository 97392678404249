<template>
  <div class="warehouse-page">
    <el-form
      :inline="true"
      :model="searchForm"
      class="bysearchForm"
      ref="dataForm"
      @keyup.enter.native="getDataList()"
    >
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-input v-model="searchForm.name" placeholder="参数名" clearable />
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList()">查询</el-button>
          <el-button
            v-if="isAuth('tc:employees:delete')"
            type="danger"
            @click="deleteHandle()"
            :disabled="detailListSelections.length <= 0"
            >批量删除</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <div class="main-container">
      <div class="aside-wrap">
        <h5>公司列表</h5>
        <el-table
          height="calc(100vh - 210px)"
          :data="dataList"
          ref="dataList"
          border
          :row-class-name="tableRowClassName"
          @selection-change="$selectionChange($event, 'dataList')"
          @row-click="$clickRow($event, 'dataList', getDetailList($event))"
          @select="$select(arguments, 'dataList')"
          @select-all="$clearSelection('dataList')"
        >
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50"
          />
          <el-table-column
            prop="name"
            header-align="center"
            align="center"
            label="公司列表"
          />
        </el-table>
        <el-pagination
          background
          @size-change="$sizeChange($event, 'dataList')"
          @current-change="$currentChange($event, 'dataList')"
          :current-page="dataListIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="dataListSize"
          :total="dataListCount"
          layout="total, sizes, prev, pager, next"
        />
      </div>
      <div class="main-wrap">
        <h5>公司员工用餐对账信息</h5>
        <el-table
          :data="detailList"
          ref="detailList"
          border
          stripe
          v-loading="detailListLoading"
          @row-click="$clickRow($event, 'detailList')"
          @selection-change="$selectionChange($event, 'detailList')"
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            prop="id"
            header-align="center"
            align="center"
            width="50"
          />
          <!--            <el-table-column-->
          <!--              prop="companyName"-->
          <!--              header-align="center"-->
          <!--              align="center"-->
          <!--              label="单位名"-->
          <!--            >-->
          <!--            </el-table-column>-->
          <el-table-column
            prop="name"
            header-align="center"
            align="center"
            label="员工名字"
          />
          <el-table-column
            prop="consignee"
            header-align="center"
            align="center"
            label="取餐人"
          />
          <el-table-column
            prop="pickupDate"
            header-align="center"
            align="center"
            label="取餐时间"
          />
          <el-table-column
            prop="mobile"
            header-align="center"
            align="center"
            label="手机号码"
          />
          <el-table-column
            prop="totalPrice"
            header-align="center"
            align="center"
            label="订单总价"
          />
          <el-table-column
            prop="actualPrice"
            header-align="center"
            align="center"
            label="实付金额"
          />
          <el-table-column
            prop="paidAt"
            header-align="center"
            align="center"
            label="付款时间"
          />
          <el-table-column
            fixed="right"
            header-align="center"
            align="center"
            width="150"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                v-if="isAuth('rc:company:info')"
                type="text"
                size="small"
                @click="showDetails(scope.row.id)"
                >查看</el-button
              >
              <el-button
                v-if="isAuth('rc:company:update')"
                type="text"
                size="small"
                @click="addOrUpdateHandle(scope.row.id)"
                >修改</el-button
              >
              <el-button
                v-if="isAuth('rc:company:delete')"
                type="text"
                size="small"
                @click="deleteHandle(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          @size-change="$sizeChange($event, 'detailList')"
          @current-change="$currentChange($event, 'detailList')"
          :current-page="detailListIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="detailListSize"
          :total="detailListCount"
          layout="total, sizes, prev, pager, next, jumper"
        />
      </div>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @success="getDataList"
    />
  </div>
</template>

<script>
import { threeListMixin, normal } from '@/mixins';
import AddOrUpdate from './reconciliation-add-or-update';

export default {
  mixins: [normal, threeListMixin],
  data() {
    return {
      searchForm: {
        name: '',
      },
      companyId: 0,
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  activated() {
    this.getDataList();
  },
  methods: {
    //获取数据列表
    getDataList() {
      this.$api({
        url: '/tc/company/combo',
        after: (data) => {
          if (data && data.code === 0) {
            if (data.list?.length) {
              this.dataList = data.list;
              this.companyId = this.dataList[0].id;
              this.$nextTick(() => {
                this.$refs.dataList.toggleRowSelection(this.dataList[0], true);
                this.getDetailList(this.dataList[0]);
              });
            } else {
              this.dataList = [];
            }
          } else {
            this.dataList = [];
          }
        },
      });
    },
    // 获取右侧数据列表
    getDetailList(row) {
      if (row) {
        this.companyId = row.id;
      }
      this.$api({
        url: '/rc/company/list',
        params: {
          page: this.detailListIndex,
          limit: this.detailListSize,
          companyId: this.companyId,
          name: this.searchForm.name,
        },
        after: (data) => {
          if (data && data.code === 0) {
            if (data.page && data.page.records?.length) {
              this.detailList = data.page.records;
              this.detailListCount = data.page.total;
            } else {
              this.detailList = [];
              this.detailListCount = 0;
            }
          } else {
            this.detailList = [];
            this.detailListCount = 0;
          }
        },
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 查看详情
    showDetails(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, true);
      });
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$confirm(`确定对这条记录进行删除操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: '/rc/company/delete',
            method: 'post',
            data: ids,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
/* element滚动条组件 隐藏水平滚动条 */
.sidebar-wrapper .el-scrollbar__wrap {
  overflow-x: hidden;
}
/*.is-horizontal {*/
/*  display: none;*/
/*}*/
/*.el-row {*/
/*  margin-bottom: 20px;*/
/*&:last-child {*/
/*   margin-bottom: 0;*/
/* }*/
/*}*/
/*.el-col {*/
/*  border-radius: 4px;*/
/*}*/
/*.bg-purple-dark {*/
/*  background: #99a9bf;*/
/*}*/
.bg-purple {
  background: #d3dce6;
}
/*.bg-purple-light {*/
/*  background: #e5e9f2;*/
/*}*/
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
/*.row-bg {*/
/*  padding: 10px 0;*/
/*  background-color: #f9fafc;*/
/*}*/
</style>
